import React, { useEffect, useState } from 'react';
import { Table, Spinner } from 'flowbite-react';
import { Helmet } from "react-helmet";

function Ranking() {
    const [rankingDataHS, setRankingDataHS] = useState([]);
    const [rankingDataBX, setRankingDataBX] = useState([]);
    const [rankingDataMinutes, setRankingDataMinutes] = useState([]);
    const [loadingHS, setLoadingHS] = useState(true);
    const [loadingBX, setLoadingBX] = useState(true);
    const [loadingM, setLoadingM] = useState(true);

    useEffect(() => {
        fetch('https://api.traderun.xyz/ranking/boxjumps')
            .then(response => response.json())
            .then(data => {
                setRankingDataBX(data);
                setLoadingBX(false);
            })
            .catch(error => {
                console.error('Error fetching ranking data:', error);
                //setLoading(false);
            });

        fetch('https://api.traderun.xyz/ranking/highscore')
            .then(response => response.json())
            .then(data => {
                setRankingDataHS(data);
                setLoadingHS(false);
            })
            .catch(error => {
                console.error('Error fetching ranking data:', error);
                //setLoading(false);
            });

        fetch('https://api.traderun.xyz/ranking/minutes')
            .then(response => response.json())
            .then(data => {
                setRankingDataMinutes(data);
                setLoadingM(false);
            })
            .catch(error => {
                console.error('Error fetching ranking data:', error);
                //setLoading(false);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Trade Run: Ranking</title>
                <meta name="description" content="Page description here." />
            </Helmet>
            <div className="flex flex-col items-center">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-3 mb-10 flex">
                    <div className="col-span-1 overflow-x-auto flex-1">
                        {loadingHS ? (
                            <div className="flex justify-center items-center h-64">
                                <Spinner size="xl" />
                            </div>
                        ) : (
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>No.</Table.HeadCell>
                                    <Table.HeadCell>Username</Table.HeadCell>
                                    <Table.HeadCell>Highscore</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {rankingDataHS.map((item, index) => (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell>{index + 1}</Table.Cell>
                                            <Table.Cell className="flex items-center">
                                                <img src="/levels/1.png" className="h-3 w-5 mb-1 mr-2" alt="Level Image" />
                                                <span className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                    {item.username}
                                                </span>
                                            </Table.Cell>
                                            <Table.Cell>{item.highscore.toLocaleString()}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </div>
                    <div className="col-span-1 overflow-x-auto flex-1">
                        {loadingBX ? (
                            <div className="flex justify-center items-center h-64">
                                <Spinner size="xl" />
                            </div>
                        ) : (
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>No.</Table.HeadCell>
                                    <Table.HeadCell>Username</Table.HeadCell>
                                    <Table.HeadCell>Boxjumps</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {rankingDataBX.map((item, index) => (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell>{index + 1}</Table.Cell>
                                            <Table.Cell className="flex items-center">
                                                <img src="/levels/1.png" className="h-3 w-5 mb-1 mr-2" alt="Level Image" />
                                                <span className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                    {item.username}
                                                </span>
                                            </Table.Cell>
                                            <Table.Cell>{item.highboxjump.toLocaleString()}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </div>
                    <div className="col-span-1 overflow-x-auto flex-1">
                        {loadingM ? (
                            <div className="flex justify-center items-center h-64">
                                <Spinner size="xl" />
                            </div>
                        ) : (
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>No.</Table.HeadCell>
                                    <Table.HeadCell>Username</Table.HeadCell>
                                    <Table.HeadCell>Minutes</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {rankingDataMinutes.map((item, index) => (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell>{index + 1}</Table.Cell>
                                            <Table.Cell className="flex items-center">
                                                <img src="/levels/1.png" className="h-3 w-5 mb-1 mr-2" alt="Level Image" />
                                                <span className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                    {item.username}
                                                </span>
                                            </Table.Cell>
                                            <Table.Cell>{item.minutes.toLocaleString()}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ranking;